import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

import Wrapper from './HomeSectionWrapper'
import BigPlus from '../Img/BigPlus'

const WordCloud = () => {
  const data = useStaticQuery(graphql`
    {
      background: file(relativePath: { eq: "wordCloud-bg.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            srcWebp
          }
        }
      }
    }
  `)

  const backgroundImage = {
    backgroundImage: `url(${data.background.childImageSharp.fluid.srcWebp})`,
  }

  return (
    <Wrapper
      className="[ bg-red ] [ has-bg has-bg--half ]"
      style={backgroundImage}
    >
      <div className="[ word-cloud ] [ grid grid-auto-rows-dense grid-cols-12 col-gap-12 ] [ relative ]">
        <span
          className="[ line ] [ row-start-1 col-start-5 ] [ absolute ]"
          style={{ top: '10%' }}
        ></span>
        <BigPlus className="[ row-start-1 col-start-5 ] [ absolute top-25 ]" />
        <p className="[ word-cloud__text--massive ] [ row-start-2 row-end-3 col-start-1 md:col-start-2 col-end-7 ] [ mb-0 ] [ font-extrabold leading-none ]">
          Shopify
        </p>
        <p
          className="[ word-cloud__text--xl ] [ row-start-2 col-start-2 md:col-start-4 ] [ self-end ] [ mb-0 absolute ] [ text-white ] [ font-extrabold leading-none ]"
          style={{ top: '36%' }}
        >
          BigCommerce
        </p>
        <p className="[ word-cloud__text--md rotate rotate--mob-none ] [ row-start-2 col-start-1 md:col-start-9 ] [ absolute top-0 md:top-25 ] [ text-white ] [ font-extrabold leading-none ]">
          Aero
        </p>
        <p className="[ word-cloud__text--lg ] [ row-start-3 row-end-4 col-start-1 md:col-start-7 ] [ mb-0 ] [ font-extrabold leading-none ]">
          WooCommerce
        </p>
        <span
          className="[ line line--short line--thick ] [ row-start-4 col-start-2 ] [ hidden md:block ]"
          style={{ top: '10%' }}
        ></span>
        <p className="[ word-cloud__text--sm ] [ row-start-4 col-start-4 md:col-start-6 md:col-span-12 ] [ text-white ] [ font-extrabold leading-none ]">
          Adobe Commerce
        </p>
      </div>
    </Wrapper>
  )
}

export default WordCloud
